<template>
  <div v-if="block.columns && block.columns.length > 0">
    <!-- Add a custom class here -->
    <DataTable :data="block.data" :options="dtOptions" class="cell-border compact gridtable">
      <thead>
        <tr>
          <th v-for="(column, index) in block.columns" :key="index">
            {{ column.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in block.data" :key="rowIndex">
          <td v-for="(cell, cellIndex) in row" :key="cellIndex" v-html="cell"></td>
        </tr>
      </tbody>
    </DataTable>
  </div>
</template>

<script>
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';

import 'datatables.net-select';
import 'datatables.net-buttons-dt'; 
import 'datatables.net-buttons/js/buttons.html5';

import jszip from 'jszip';
import pdfmake from 'pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";

DataTable.use(DataTablesCore);
DataTablesCore.Buttons.jszip(jszip);
DataTablesCore.Buttons.pdfMake(pdfmake);

export default {
  components: {
    DataTable
  },
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dtOptions: {
        layout: {
          bottomStart: {
            buttons: [
              {
                extend: 'collection',
                text: 'Export',
                buttons: ['copy', 'csv', 'excel', 'pdf']
              }
            ]
          }
        },
        ordering: false,
        order: [],
        searching: false,
        paging: false,
        autoWidth: false
      },
    };
  }
}
</script>

<style>
/* Target only the first-column cells in our custom .gridtable */
.gridtable th:first-child, 
.gridtable td:first-child {
  background-color: rgb(245, 245, 245)
}

.gridtable.dataTable thead th {
  background-color:rgb(245, 245, 245);
  color: rgb(46, 46, 51);
}

.gridtable.dataTable {
  table-layout: fixed; 
  width: 100%;
}

/* This targets all header and body cells in your gridtable */
.gridtable.dataTable th,
.gridtable.dataTable td {
  font-size: 11px;
}

.gridtable.dataTable th:first-child,
.gridtable.dataTable td:first-child {
  background-color: rgb(245, 245, 245);
  width: 100px;
}

</style>
