<template>
  <div>
    <!-- Only the router-view, no navbar or footer -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Blank'
};
</script>

<style lang="scss">
@import '../../node_modules/bulma';

$curve-blue:rgb(108, 200, 239);
$curve-blue-hyper:rgb(24, 182, 233);

$dark-text:rgb(46, 46, 51);
$light-background:rgb(249, 250, 251);
$border-color:rgb(204, 204, 204);

/* Universal Light Mode Styles */
body {
    background-color: white;
    color: $dark-text; /* Dark text for contrast on a white background */
}

/* Enforce white background across the page */
.white-background {
    background-color: white !important;
    color: $dark-text; /* Set a contrasting text color */
}

/* Light grey background for footer */
.light-grey-background {
    background-color: $light-background;
    color: $dark-text;
}

// Color that clickable links should appear
a {
  color: $curve-blue-hyper;
}

// Default button settings
button {
    font-weight: bold;
    border-radius: 5px;
    padding: 10px 20px;     /* padding around text area aka make button larger */
    background-color: $curve-blue; /* background color */
    cursor: pointer;        /* Pointer cursor on hover */
}

button:hover {
    background-color: lightgreen; /* Optional: change background color on hover */
}

button:disabled {
    cursor: not-allowed;
}

// Navbar specific settings
.navbar{
    background-color: $light-background;
}

.navbar-menu{
    background-color: $light-background;
}
.navbar-menu .button.adjusted {
    background-color: white;
    color: $dark-text;
    height: 40px;
}

.navbar-menu .button.secondary {
    background-color: $curve-blue;
    color: $dark-text;
    height: 40px;
}
.navbar-burger {
  color: black;
  background-color: $curve-blue
}

// Search bar

.search-bar {
    background-color: white;
    color: $dark-text;
    border: 1px solid $border-color; /* Make sure to specify the border width and style */
    border-radius: 4px;
}
.search-bar::placeholder {
    color: $border-color; /* Ensures the placeholder text is black */
    opacity: 1; /* Ensures full opacity for the placeholder */
}

// Left side container thing

.layout-container {
    border: 1px solid;
    border-color: $border-color;
    display: flex;
}

.layout-container-left {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 200px;
    background-color: $light-background;
} 

.left-list-text {
    display: block;
    padding-left: 25px;
    padding-right: 5px;
    padding-bottom: 10px;
    color: $curve-blue-hyper; /* Set hyperlink color to curve blue */
}

.left-list-header-text {
    display: block;
    font-weight: bold;
    padding-left: 10px;
    padding-bottom: 10px;
}

.main-section{
    overflow-x: auto;
}
// Settings for column displays & svg mostly for model pages
// Note: A Vue can contain a left-column AND (a right-column OR svg-container)
.container {
    display: flex;
}
.left-column {
    flex: 0 0 40%;
} 
.right-column {
    padding-left: 20px;
    width: 60%;
}

.svg-container {
    width: 60%;
    padding: 100px;
    overflow-y: auto;  /* Allows scrolling if content overflows */
}
.scaled-svg {
    transform: scale(0.60);
    transform-origin: top left;  /* Ensure the scaling starts from the top left */
}

// Loading settings
.loading-symbol {
    width: 60px;
    aspect-ratio: 1;
    display: flex;
    color: #000;
    border: 4px solid;
    box-sizing: border-box;
    border-radius: 50%;
    background: 
        radial-gradient(circle 5px, currentColor 95%,#0000),
        linear-gradient(currentColor 50%,#0000 0) 50%/4px 60% no-repeat;
    animation: l1 2s infinite linear;
}

.loading-symbol:before {
    content: "";
    flex: 1;
    background: linear-gradient(currentColor 50%, #0000 0) 50%/4px 80% no-repeat;
    animation: inherit;
}
@keyframes l1 {
    100% { transform: rotate(1turn); }
}

</style>
