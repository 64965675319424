<template>
  <div style="overflow-x: auto;">
    <div :style="gridStyles" class="grid">
      <template v-for="row in block.rows" :key="row">
        <template v-for="col in block.columns" :key="col">
          <div
            class="cell"
            :class="{ occupied: isOccupied(row, col) }"
            style="font-size: 12px;"
          >
            {{ row }}{{ col }}
            <div v-if="isOccupied(row, col)">
              <br />
              <!-- Now we iterate over each tube in the cell -->
              <template v-for="tube in getTubes(row, col)" :key="tube.name">
                <a
                  :href="`${tube.url}`"
                  style="font-size: 12px; color: #18B6E9; font-weight: bold;"
                >
                  {{ tube.name }}
                </a>
                <br />
              </template>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  computed: {
    gridStyles() {
      let columnWidth;

      // If there's only one column, make it wider.
      if (this.block.column_number === 1) {
        columnWidth = 150;
      } 
      // If there are more, cap at 100px
      else {
        columnWidth = 100;
      } 

      return {
        display: 'grid',
        'grid-template-rows': `repeat(${this.block.row_number}, 1fr)`,
        'grid-template-columns': `repeat(${this.block.column_number}, ${columnWidth}px)`,
        'grid-column-gap': '0',
        'grid-row-gap': '0',
      };
    }
  },
  methods: {
    isOccupied(row, col) {
      return this.block.tubes.some(tube => tube.row === row && tube.column === col);
    },
    getTubes(row, col) {
      // Return all tubes that occupy this cell
      return this.block.tubes.filter(tube => tube.row === row && tube.column === col);
    },
  }
};
</script>

<style scoped>
.cell {
  border: 1px solid #ccc;
  display: flex;
  align-items: left;
  justify-content: left;

}
.cell.occupied {
  background-color: #aaffaa;
}
</style>
