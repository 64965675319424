import axios from 'axios';
import { DisableButtonMixin } from '@/mixins/DisableButtonMixin.js';

export default {
    mixins: [DisableButtonMixin],

    data() {
        return {
            isLoading: false,
            pageElements: [],
            pageElementsRight: [],
            plotImage: '',
            svgContent: '',
            responseMessage: '',
            responseError: '',
            tableData: {
                items: [],
                columns: []
            },
            tableComponentExists: false
        };
    },

    methods: {
        handleErrorResponse(error, defaultMessage = 'An error occurred - please reload the page.') {
            if (error.response) {
                const { status, data } = error.response;
                switch (status) {
                    case 400: return data.error || 'Invalid input.';
                    case 500: return data.error || 'Server error.';
                    default: return defaultMessage;
                }
            }
            return defaultMessage;
        },
        
        resetState() {
            this.isLoading = true;
            this.pageElements = [];
            this.pageElementsRight = [];
            this.responseMessage = null;
            this.responseError = null;
            this.tableData = {
                items: [],
                columns: []
            };
        },  

        async fetchPageStructure(apiUrl) {
            this.resetState();
            const headers = {};
            const token = localStorage.getItem('authToken');
            if (token) {
                headers['Authorization'] = `Token ${token}`;
            }

            try {
                const response = await axios.get(apiUrl, { headers });

                if (response.status !== 200) {
                    throw new Error();
                }
                
                this.pageElements = response.data.body;
                this.pageElementsRight = response.data.bodyRight;
                this.svgContent = response.data.svg;

            } catch (error) {
                if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                    // HashedTokenAuthentication workflow returns a 403 if authorization token header is invalid in some way
                    console.log(error.response);
                    localStorage.removeItem('authToken');  // remove auth token for all cases were the authtoken may just be expired
                    this.$router.push({ name: 'login' });  // push to login page
                } else {
                    // Likely a backend error - display on frontend
                    this.responseError = this.handleErrorResponse(error);;
                }
            } finally {
                this.isLoading = false;
            }
        },

        async externalFetchPageStructure(apiUrl) {
            this.resetState();
            const headers = {};
            const token = localStorage.getItem('externalAuthToken');
            if (token) {
                headers['Authorization'] = `Token ${token}`;
            }

            try {
                const response = await axios.get(apiUrl, { headers });

                if (response.status !== 200) {
                    throw new Error();
                }

                this.pageElements = response.data.body;
                this.pageElementsRight = response.data.bodyRight;
                this.svgContent = response.data.svg;

            } catch (error) {
                // Hack necessitated by the fact that I cannot for the life of me get ExternalAuth to return
                // a dang 401 for unauthorized authentication to distinguish it from a 403 WHYYYYY
                const errorDetail = error.response.data.detail;

                if ((errorDetail === 'Authentication incomplete') || error.response.status === 401) {
                    console.log('Not Authenticated');
                    // Lack of authorization generates the 401 response, 403 is Forbidden, usually CORS issue
                    localStorage.removeItem('externalAuthToken');
                    this.$router.push({ name: 'externalauth' });
                } else if (error.response && (error.response.status === 403)) {
                    console.log('Forbidden');
                    // Lack of authorization generates the 401 response, 403 is Forbidden, usually CORS issue
                    localStorage.removeItem('externalAuthToken');
                    this.$router.push({ name: 'denied' });

                } else if (error.response && (error.response.status === 400)) {
                    // User entered bad value - display error on frontend so that the user can make a correction
                    this.responseError = error.response.data.error;
                } else if (error.response && (error.response.status === 500)) {
                    // Error on backend - may deal with this differently in the future, but for now, display on frontend
                    this.responseError = error.response.data.error;
                } else {
                    // Likely a backend error - display on frontend
                    this.responseError = `${error.response.data.error}`;
                }
            } finally {
                this.isLoading = false;
            }
        },

        async handleFormSubmission(apiUrl, formData) {
            await this.FormSubmission(apiUrl, formData, 'authToken');
        },
        async externalHandleFormSubmission(apiUrl, formData) {
            await this.FormSubmission(apiUrl, formData, 'externalAuthToken');
        },
        async FormSubmission(apiUrl, formData, tokenKey = 'authToken') {
            this.responseError = '';
            this.responseMessage = '';
            this.isLoading = true;
            this.plotImage = '';
        
            try {
                // Prepare headers with the token
                const headers = {};
                const token = localStorage.getItem(tokenKey);
                if (token) {
                    headers['Authorization'] = `Token ${token}`;
                }

                const response = await axios.post(apiUrl, formData, { headers });
        
                if (response.status !== 200) {
                    throw new Error();
                }
        
                // Process response data
                if (response.data.message) {
                    this.responseMessage = response.data.message;
                }
                if (response.data.error) {
                    this.responseError = response.data.error;
                }
                if (response.data.file) {
                    this.downloadFile(response.data.file.csv, response.data.file.name);
                }
                if (response.data.component === 'table') {
                    this.tableData.items = response.data.items;
                    this.tableData.columns = response.data.columns;
                    this.tableComponentExists = true;
                }
                if (response.data.plot) {
                    this.plotImage = `data:image/png;base64,${response.data.plot.image}`;
                }
            } catch (error) {
                // Handle errors
                if (error.response) {
                    if (error.response.status === 400) {
                        this.responseError = error.response.data.error;
                    } else if (error.response.status === 500) {
                        this.responseError = error.response.data.error;
                    } else {
                        this.responseError = 'An uncaught error occurred during the upload.';
                    }
                } else {
                    this.responseError = 'An uncaught error occurred during the upload.';
                }
            } finally {
                this.isLoading = false;
            }
        },

        downloadFile(fileContent, fileName) {
            // Infer file type from the file extension
            let mimeType = 'text/plain';  // Default to text/plain in case file type can't be inferred
            let blobContent = fileContent;
        
            const fileExtension = fileName.split('.').pop().toLowerCase(); // Get file extension
        
            if (fileExtension === 'csv') {
                mimeType = 'text/csv;charset=utf-8;';

            } else if (fileExtension === 'pdf') {
                mimeType = 'application/pdf';
                const byteCharacters = fileContent;
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                blobContent = new Uint8Array(byteNumbers);

            } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; // MIME for Excel
                const byteCharacters = atob(fileContent);  // Decode Base64
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i) & 0xff;
                }
                blobContent = new Uint8Array(byteNumbers);
            }
        
            // Create a Blob with the file content and inferred MIME type
            const blob = new Blob([blobContent], { type: mimeType });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            
            // Set the download attributes
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            
            // Append the link to the body and trigger the download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },  
    },
};
