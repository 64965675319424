<template>
  <!-- Only render if columns exist -->
  <div v-if="block.columns && block.columns.length > 0">    

    <!-- Pass the data in and build both <thead> and <tbody> -->
    <DataTable :data="block.data" :options="dtOptions" class="display compact">
      <thead>
        <tr>
          <th v-for="(column, index) in block.columns" :key="index">
            {{ column.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in block.data" :key="rowIndex">
          <!-- Use v-html to preserve any HTML in the cell (e.g. <a> tags) -->
          <td v-for="(cell, cellIndex) in row" :key="cellIndex" v-html="cell"></td>
        </tr>
      </tbody>
    </DataTable>
  </div>
</template>

<script>
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';

import 'datatables.net-select';
import 'datatables.net-buttons-dt'; 
import 'datatables.net-buttons/js/buttons.html5';

import jszip from 'jszip';
import pdfmake from 'pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";

DataTable.use(DataTablesCore);
DataTablesCore.Buttons.jszip(jszip);
DataTablesCore.Buttons.pdfMake(pdfmake);


export default {
  components: {
    DataTable
  },
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dtOptions: {
        layout: {
          bottomStart: {
            buttons: [
              {
                extend: 'collection',
                text: 'Export',
                buttons: ['copy', 'csv', 'excel', 'pdf']
              }
            ]
          }
        },
        // keep feature to allow ordering, but don't execute default sort
        ordering: true,
        order: []
      },
    };
  }
}
</script>

<style lang="scss">
@import 'datatables.net-dt';

.dt-buttons .dt-button {
  font-size: 0.8rem;
  background-color: rgb(245, 245, 245);
  color: rgb(51, 51, 51);
  padding: 0.5rem 0.8rem;
  border: 1px solid rgb(170, 170, 170);
  border-radius: 3px;
}
.dt-buttons .dt-button:hover {
  background-color: rgb(225, 225, 225);
}

/* Now override the dropdown that appears when clicking "Export": */
.dt-button-collection {
  position: absolute;
  
  /* If you want a light background for the list items: */
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 3px;

  /* Some padding for the text from the right side of the dropdown: */
  padding: 0.5rem;
}

/* Each button in the dropdown is turned into a list-like item, rather than a 'button': */
.dt-button-collection .dt-button {

  text-align: left;

  /* Remove the same styling as the main button: */
  background: none;
  border: 1px solid rgb(170, 170, 170);
  color: rgb(51, 51, 51);

  /* A bit of spacing between list items: */
  padding: 0.8rem 0.8;

  font-size: 0.8rem;
  font-weight: normal;

}

.dt-button-collection .dt-button:hover {
  background-color: rgb(225, 225, 225);
}

.display tbody tr:hover {
  background-color: rgb(225, 225, 225); /* Slightly darker gray on hover */
}

table.dataTable thead th {
  background-color:rgb(108, 200, 239); /* or any shade of blue you like */
  color: rgb(46, 46, 51);
}

</style>
