import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/views/Home.vue'

import Wetlab from '@/layouts/Wetlab.vue';

import ExternalHome from '@/views/External/ExternalHome.vue'

import WetlabHome from '../views/Wetlab/WetlabHome.vue'
import Login from '../views/Wetlab/Login.vue'
import Search from '../views/Wetlab/Search.vue'
import AuthCallback from '../views/Wetlab/AuthCallback.vue'

import accessionRoutes from './accessionRoutes.js'
import afpRoutes from './afpRoutes.js'
import collectionsitesRoutes from './collectionsitesRoutes'
import fulfillmentRoutes from './fulfillmentRoutes.js'
import modelRoutes from './modelRoutes.js'
import postampRoutes from './postampRoutes'
import preampRoutes from './preampRoutes'

const WETLAB_URL = process.env.VUE_APP_WETLAB_URL;
const EXTERNAL_URL = process.env.VUE_APP_EXTERNAL_URL;

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/Blank.vue'),
    children: [
      {
        path: '',
        component: Home,
        name: 'home'
      },
      {
        path: 'wetlab/login',
        name: 'login',
        component: Login
      },
    ],
  },

  {
    path: `${EXTERNAL_URL}`,
    component: () => import('@/layouts/External.vue'),
    children: [
      {
        path: '',
        name: 'extneralhome',
        component: ExternalHome
      },
      ...collectionsitesRoutes,
    ]
  },

  { path: `${WETLAB_URL}`,
    component: Wetlab,
    children: [
      {
        path: '',
        name: 'wetlabhome',
        component: WetlabHome
      },
      {
        path: 'auth/callback',
        name: 'callback',
        component: AuthCallback
      },
      {
        path: 'search',
        name: 'search',
        component: Search
      },
      ...accessionRoutes,
      ...afpRoutes,
      ...fulfillmentRoutes,
      ...modelRoutes,
      ...postampRoutes,
      ...preampRoutes,
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: () => import('../views/NotFound.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
