import GenericWebpageView from '@/views/Wetlab/GenericWebpageView.vue';
import DynamicModelView from '@/views/Wetlab/DynamicModelView.vue';

export default [
  {
    path: `postamp`,
    name: 'PostAmp',
    component: GenericWebpageView
  },
  {
    path: `postamp/postampbatch:pk`,
    name: 'PostAmpBatch',
    component: DynamicModelView,
  },
]
