import ExternalAuth from '@/views/External/CollectionSites/ExternalAuth.vue'
import Denied from '@/views/External/CollectionSites/Denied.vue'
import ExternalGenericWebpageView from '@/views/External/CollectionSites/ExternalGenericWebpageView.vue'


export default [
  {
    path: `auth`,
    component: ExternalAuth,
    name: 'externalauth'
  },
  {
    path: `denied`,
    component: Denied,
    name: 'denied'
  },
  {
    path: `collectionsites/home`,
    component: ExternalGenericWebpageView,
    name: 'Collection Sites Home'
  },
  {
    path: `collectionsites/kit_status`,
    component: ExternalGenericWebpageView,
    name: 'Kit Status'
  },
  {
    path: `collectionsites/markkitsreceived`,
    component: ExternalGenericWebpageView,
    name: 'Mark Kits Received'
  },
  {
    path: `collectionsites/markkitsabandoned`,
    component: ExternalGenericWebpageView,
    name: 'Mark Kits Discarded'
  },
  {
    path: `collectionsites/manifest`,
    component: ExternalGenericWebpageView,
    name: 'Generate eManifest'
  },
]

