import GenericWebpageView from '@/views/Wetlab/GenericWebpageView.vue';
import DynamicModelView from '@/views/Wetlab/DynamicModelView.vue';

export default [
  {
    path: `afp`,
    name: 'AFP',
    component: GenericWebpageView
  },
  {
    path: `afp/afpplateslist`,
    name: 'afpPlates',
    component: GenericWebpageView,
  },
]
