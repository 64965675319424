<template>
  <div>
    <div v-if="isLoading" class="loading-symbol"></div>

    <ResponseComponent
      :responseMessage="responseMessage"
      :responseError="responseError"
      :tableComponentExists="tableComponentExists"
      :tableData="tableData"
      :plotImage="plotImage"
    />
  </div>
  <div class="container">
    <div class="left-column">
      <component
        v-for="block in pageElements"
        :key="block.index"
        :is="block.component"
        :block="block"
        :isLoading="isLoading"
        @formSubmitted="submitForm"
      ></component>
    </div>
    <div class="right-column">
      <component
        v-for="block in pageElementsRight"
        :key="block.index"
        :is="block.component"
        :block="block"
        :isLoading="isLoading"
        @formSubmitted="submitForm"
      ></component>
    </div>
  </div>
</template>

<script>
import ActionMixin from '@/mixins/ActionMixin.js';

export default {
  mixins: [ActionMixin],
  mounted() {
    const modelName = this.$route.name; // Dynamically get the model name from the route
    document.title = `${modelName.charAt(0).toUpperCase() + modelName.slice(1)} | ${modelName}${this.$route.params.pk}`;
  },
  created() {
    const modelName = this.$route.name.toLowerCase(); // Dynamically get the model name from the route
    this.fetchPageStructure(`${process.env.VUE_APP_WETLAB_URL}/${modelName}${this.$route.params.pk}/`);
  },
  methods: {
    submitForm(formData) {
      const modelName = this.$route.name.toLowerCase(); // Dynamically get the model name from the route
      // Add modelName to formData
      formData.append('model', modelName);
      this.handleFormSubmission(`${process.env.VUE_APP_WETLAB_URL}/${modelName}${this.$route.params.pk}/`, formData);
    },
  },
};
</script>
