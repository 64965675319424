import GenericWebpageView from '@/views/Wetlab/GenericWebpageView.vue';


export default [
  {
    path: `accession`,
    name: 'Accession',
    component: GenericWebpageView
  },
//   {
//     path: `accession/crc`,
//     name: 'CRC Patients',
//     component: GenericWebpageView
//   },
  {
    path: 'accession/returnedkits',
    name: 'Returned Kits',
    component: GenericWebpageView
  },
  {
    path: `accession/accessionrack`,
    name: 'Accession Rack',
    component: GenericWebpageView
  },
  {
    path: 'accession/freezerobjcreation',
    name: 'Freezer Obj Creation',
    component: GenericWebpageView,
  },
  {
    path: 'accession/freezerobjlocation',
    name: 'Freezer Obj Location',
    component: GenericWebpageView,
  },
]
