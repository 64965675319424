<template>
  <div class="local-font-size">
    <label style="margin-right: 8px;" v-html="block.instructions"></label>
    <div class="spacer"></div>
    <template v-if="block.items && block.items.length > 0">
      <template v-for="(item, index) in block.items" :key="index">

        <!-- Render different input types based on 'type' -->

        <div v-if="item.type === 'text-box'">
          <label style="margin-right: 8px;">{{ item.title }}</label>
          <input type="text" v-model="formData[item.variable_name]">
          <div class="spacer"></div>
        </div>

        <div v-else-if="item.type === 'large-text-box'">
          <textarea v-model="formData[item.variable_name]" :placeholder="item.title" rows="5" cols="40"></textarea>
          <div class="spacer"></div>
        </div>

        <div v-else-if="item.type === 'checkbox'">
          <input type="checkbox" v-model="formData[item.variable_name]" style="transform: scale(1.2);">
          <span>{{ item.title }}</span>
          <div class="spacer"></div>
        </div>

        <label v-else-if="item.type === 'dropdown'">
          {{ item.title }}:
          <select v-model="formData[item.variable_name]">
            <option v-for="option in item.options" :key="option.value" :value="option.value">{{ option.label }}</option>
          </select>
          <div class="spacer"></div>
        </label>

        <!-- Add Upload File functionality -->
        <div v-else-if="item.type === 'uploadfile'" style="display: flex; align-items: center; margin-bottom: 6px;">
          <h1>{{ item.title }}</h1>
          <input type="file" :ref="'fileInput' + index" @change="handleFileSelection(item.variable_name, index)" accept=".csv, .xls, .xlsx" multiple />
        </div>

        <div v-else-if="item.type === 'selectedtable'">
          <SelectedTable ref="selectedTable" :block="item.items" />
        </div>

      </template>
    </template>
    <!-- Always render the submit button -->
    <button @click="submitForm" :disabled="isSubmitting">Submit</button>
  </div>
</template>

<script>
import SelectedTable from './SelectedTable.vue';

export default {
  components: {
    SelectedTable
  },
  props: {
    block: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formData: {},
      selectedFiles: {},
      isSubmitting: false
    };
  },
  watch: {
    isLoading(newValue) {
      this.isSubmitting = newValue;
    }
  },
  methods: {
    handleFileSelection(variable_name, index) {
      const input = this.$refs['fileInput' + index][0];
      if (input.files && input.files.length > 0) {
        // Convert filelist to array
        this.selectedFiles[variable_name] = Array.from(input.files);
      } else {
        this.selectedFiles[variable_name] = null;
      }
    },

    submitForm() {
      this.isSubmitting = true;

      const selectedTableRef = this.$refs.selectedTable;
      const selectedTable = Array.isArray(selectedTableRef) ? selectedTableRef[0] : selectedTableRef;
      if (selectedTable && typeof selectedTable.getSelectedRows === 'function') {
        const selectedRows = selectedTable.getSelectedRows();
        this.formData['selected_rows'] = JSON.stringify(selectedRows);
      }

      const formDataObj = new FormData();

      // -- Append non-file fields --
      for (let key in this.formData) {
        // e.g. formDataObj.append('username', 'johndoe');
        formDataObj.append(key, this.formData[key]);
      }

      // -- Append file fields --
      // 'selectedFiles' is an object where each key holds an array of File objects
      for (let variableName in this.selectedFiles) {
        const fileArray = this.selectedFiles[variableName];
        if (Array.isArray(fileArray)) {
          fileArray.forEach(file => {
            // The first argument is how Django will see the field name
            formDataObj.append(variableName, file);
          });
        }
      }

      // Optionally, append other data (e.g., an "action" field)
      formDataObj.append('action', this.block.action);
      this.$emit('formSubmitted', formDataObj);
    }
  }
};
</script>

<style scoped>
button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Apply font size only within this component */
.local-font-size {
  font-size: 12px;
  background-color: rgb(248, 249, 250);
  padding: 16px;
  border-radius: 8px;
  width: 800px;
}

/* Style for spacing between elements */
.local-font-size .spacer {
  height: 6px;
}

/* Style for the heading */
.local-font-size h1 {
  font-size: 12px;
  margin-right: 12px;
  margin-bottom: 0;
}

/* Style for input elements */
.local-font-size input,
.local-font-size textarea,
.local-font-size select {
  font-size: 12px;
}

/* Add styles for large text box */
.local-font-size textarea {
  font-size: 14px;
  border: 1px solid black;
  padding: 8px;
  border-radius: 4px;
  outline: none;
  animation: caret-blink;
}

</style>
