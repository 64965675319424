<template>
    <div>

        <div v-if="!isExternalLoggedIn">
            <ResponseComponent
            :responseMessage="responseMessage"
            :responseError="responseError"
            :tableComponentExists="tableComponentExists"
            :tableData="tableData"
            />

            <component 
                v-for="block in pageElements" 
                :key="block.index" 
                :is="block.component" 
                :block="block" 
                @formSubmitted="submitAuthForm"
            ></component>
        </div>

        <div v-else>
            <div @click="onLogout" class="logout-button">
                Logout
            </div>
        </div>
    </div>

</template>

<script>
import ActionMixin from '@/mixins/ActionMixin.js';
import axios from 'axios';

export default {
    mixins: [ActionMixin],
    data() {
    return {
        authorizationUrl: '',
        user: '',
        isExternalLoggedIn: localStorage.getItem('externalAuthToken') ? true : false,  
    };
  },
    mounted() {
        document.title = `Collection Sites Authentication`;
    },
    created() {
        this.externalFetchPageStructure('/external/auth/');
    },
    
    methods: {
        async submitAuthForm(formData){
            // this.externalHandleFormSubmission(`${process.env.VUE_APP_EXTERNAL_URL}/auth/`, formData);

            this.responseError = '';
            this.responseMessage = '';
            this.isLoading = true;
            this.plotImage = '';
            const formPayload = new FormData();

            try {

                const response = await axios.post(`${process.env.VUE_APP_EXTERNAL_URL}/auth/`, formData);
                if (response.status !== 200) {
                    throw new Error();
                }
                if (response.data.success) {
                    localStorage.setItem('externalAuthToken', response.data.token);
                    this.$router.push({ name: 'collectionsiteshome' })
                        .then(() => {
                            window.location.reload();
                        });
                }
                // Process response data
                if (response.data.message) {
                    this.responseMessage = response.data.message;
                }
                if (response.data.error) {
                    this.responseError = response.data.error;
                }
            } catch (error) {
                // Handle errors
                if (error.response) {
                    if (error.response.status === 400) {
                        this.responseError = error.response.data.error;
                    } else if (error.response.status === 500) {
                        this.responseError = error.response.data.error;
                    } else {
                        this.responseError = 'An uncaught error occurred during the upload.';
                    }
                } else {
                    this.responseError = 'An uncaught error occurred during the upload.';
                }
            } finally {
                this.isLoading = false;
            }
        },
        onLogout(){
            localStorage.removeItem('externalAuthToken');
            this.isExternalLoggedIn = false;
            window.location.reload(); // Reload the page or handle post-logout behavior
        },
    }
};
</script>

<style>
.logout-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #18B6E9;
  color: black;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.logout-button:hover {
  background-color: lightgreen; /* Optional: Change on hover */
}
</style>