import { createApp } from 'vue';
import Blank from './layouts/Blank.vue';
import router from './router';
import store from './store';
import axios from 'axios';

// Import components
import Grid from '@/components/Grid.vue';
import Head from '@/components/Head.vue';
import List from '@/components/List.vue';
import Space from '@/components/Space.vue';
import DataTableComp from '@/components/DataTable.vue';
import Table from '@/components/Table.vue';
import GridTable from '@/components/GridTable.vue';
import Parameter from '@/components/Parameter.vue';
import PlacesParameter from '@/components/PlacesParameter.vue';
import SelectedTable from '@/components/SelectedTable.vue';
import ResponseComponent from '@/components/ResponseComponent.vue';

// Set axios defaults
axios.defaults.baseURL = `${process.env.VUE_APP_AXIOS_BASE_URL}`

axios.defaults.withCredentials = true;

// Create Vue app instance
const app = createApp(Blank);

// Use router and store
app.use(router);
app.use(store);

// Globally register components
app.component('Grid', Grid);
app.component('Head', Head);
app.component('List', List);
app.component('Space', Space);
app.component('Datatablecomp', DataTableComp);
app.component('Table', Table);
app.component('Gridtable', GridTable);
app.component('Parameter', Parameter);
app.component('Placesparameter', PlacesParameter);
app.component('Selectedtable', SelectedTable);
app.component('ResponseComponent', ResponseComponent);

// Mount the app
app.mount('#app');
